import { useCallback, useEffect, useMemo } from 'react';
import { LivenessInformation } from './components/liveness-information';
import {
	activeScreenState,
	cameraPermissionDisplayMessage,
	isCameraPermission,
} from './states';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FaceEnroll } from './components/facial-enroll';
import { SelfieDetailPage } from './components/selfie-detail/selfie-detail';
import { CameraSettings } from 'views/camera-settings';
import { Loader, Modal } from '@storybook';
import { CameraPermisionsErrors } from './constant';
import useOrientation from 'utils/detect-oriantation';
import { THE_KYB_PROVIDER_TOKEN } from 'views/kyb/constants';
import useFetchWithToken from 'hooks/use-fetch-with-token/use-fetch-with-token';
import { useSharedVariables } from 'hooks';
import { APIS } from 'constants/api';
import { CountryStateListState } from 'views/kyb/stores';
import { WebComponentMetaDataState } from 'states';
import { useFaceRecognitionRequests } from 'views/face-recognition/stores';

export const FaceMain = () => {
	const activeScreen = useRecoilValue(activeScreenState);
	const webComponentMetaData = useRecoilValue(WebComponentMetaDataState);
	const [countryList, setCountryList] = useRecoilState(CountryStateListState);
	const [cameraPermission, setCameraPermission] = useRecoilState<
		boolean | null
	>(isCameraPermission);
	const orientation = useOrientation();
	const [cameraPermissionMessage, setCameraPermissionMessage] =
		useRecoilState<string>(cameraPermissionDisplayMessage);
	const { fetchData } = useFetchWithToken();
	const { envHost } = useSharedVariables();

	//keep this commented code might use later
	const { submitFaceRecognition } = useFaceRecognitionRequests();

	const { biometric = true } = webComponentMetaData ?? {};

	useEffect(() => {
		if (!biometric) {
			submitFaceRecognition();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [biometric]);
	const fetchCountryData = useCallback(async () => {
		const { data } = await fetchData(
			APIS.COUNTRY_LIST,
			THE_KYB_PROVIDER_TOKEN[envHost as keyof typeof THE_KYB_PROVIDER_TOKEN]
		);
		setCountryList(data);
	}, [envHost, fetchData, setCountryList]);

	useEffect(() => {
		if (!countryList?.length) {
			fetchCountryData();
		}
		const checkCameraPermission = () => {
			navigator.mediaDevices
				.getUserMedia({ video: true })
				.then(stream => {
					// Camera permission is granted
					setCameraPermission(true);
					// Stop the stream immediately
					stream.getTracks().forEach(track => track.stop());
				})
				.catch(err => {
					// Camera permission is not granted
					if (err.name === 'NotReadableError') {
						// Handling hardware camera issue here
						setCameraPermissionMessage(
							CameraPermisionsErrors.Not_Readable_Error
						);
						// Handle 'Device in use' error
					} else if (err.message === 'Device in use') {
						// Handling device in  use case
						setCameraPermissionMessage(CameraPermisionsErrors.Device_in_use);
					} else {
						setCameraPermissionMessage(
							CameraPermisionsErrors.Camera_settings_OFF
						);
					}
					setCameraPermission(false);

					// Retry after a short delay
					setTimeout(checkCameraPermission, 1000); // Retry after 1 second
				});
		};
		if (biometric) {
			checkCameraPermission(); // Initial permission check
		}
	}, [
		biometric,
		countryList?.length,
		fetchCountryData,
		setCameraPermission,
		setCameraPermissionMessage,
	]);

	const renderMainComponent = useMemo(() => {
		switch (activeScreen) {
			case 'liveness-information':
				return <LivenessInformation />;
			case 'facial-enroll':
				return <FaceEnroll />;
			case 'selfie-screen':
				return <SelfieDetailPage />;
			default:
				return <></>;
		}
	}, [activeScreen]);

	const renderModal = useMemo(() => {
		return (
			<Modal
				showCloseBtn={false}
				className="full-screen-modal"
				isOpen={orientation === 'landscape'}
				modalName={''}
				optionalClassName="rotate-modal"
			>
				<div className="rotate-modal--body-wrapper">
					<div className="rotate-logo">
						<i className="ri-rotate-lock-fill"></i>
						<div className="rotate-logo-desp">
							Rotate your phone to portrait mode for the best experience.
						</div>
					</div>
				</div>
			</Modal>
		);
	}, [orientation]);

	const renderComponent = useMemo(() => {
		if (cameraPermission === null) {
			return (
				<div className="FacialLoader">
					<Loader />;
				</div>
			);
		} else if (!cameraPermission) {
			return <CameraSettings cameraTypeError={cameraPermissionMessage} />;
		} else return renderMainComponent;
	}, [cameraPermission, cameraPermissionMessage, renderMainComponent]);

	return (
		// <div>{orientation === 'landscape' ? renderModal : renderComponent}</div>
		<>
			{renderModal} {renderComponent}
		</>
	);
};
